html {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
    overflow-y: auto !important;
    padding: 0 !important;
}

:root {
    --padding-width: 10px;
    --navigation-height: 150px;
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("./images/ga_img25.png");
    background-size: 800px auto;
    background-repeat: no-repeat;
    background-position-y: calc(var(--navigation-height) + 80px);
    background-position-x: calc(50% + 300px);
}

.page {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 var(--padding-width) var(--navigation-height);
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 2 * var(--padding-width));
    overflow: hidden;
    align-content: space-around;
    flex-direction: column;
}

.flexbox-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 2 * var(--padding-width));
    align-self: stretch;
    padding: 20px 0;
}
